<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          primary-title
        >
          {{user_edit ? 'Edit' : 'Create'}} User Role
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>

                      <v-col cols="12">
                          <v-text-field
                          v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="kode"
                          label="Kode"
                          required
                          ></v-text-field>
                          <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                      </v-col>
                    </v-row>
               </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="user_edit ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  props: ['dialog', 'user_edit'],
  mixins:[GlobalMixin],
  methods: {
    async send_data() {
      const data = new URLSearchParams()
      data.append('kode', this.kode)
      data.append('note', this.note)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = await this.send_data()
      await this.axios.post('v1/users-role/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('users-role','users_role')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = await this.send_data()
      await this.axios.put('v1/users-role/update/' + this.user_edit.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update',
            color_snakbar: 'success'
          })
          await this.go('users-role','users_role')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.kode = ''
      this.note = ''
      this.karyawan = ''
      this.name = ''
      this.password = ''
    },
    remove(data) {
      this[data] = ''
    },
    img(folder = null ,file = null) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
  },


  watch: {
    user_edit: function(newValue, oldVal) { // watch it
        if (newValue) {
          this.kode = newValue.kode
          this.note = newValue.note
          this.name = newValue.name
          newValue.branchs.forEach(x => {
            this.branch.push(x.id)
          })

        }
    },

  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    kode: '',
    email: '',
    nameRules: [
      v => !!v || 'Required field'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
  },
  created () {

  }
}
</script>
