<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="progress ? '200px':'650px'"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
    <v-card
        color="primary"
        dark
        v-if="progress"
        width="150px"
    >
      <v-card-text>
        Please stand by
      <v-progress-linear
      width="150px"
      indeterminate
      color="white"
      class="mb-0"
      ></v-progress-linear>
      </v-card-text>
    </v-card>

      <v-card
      tile
      v-else
      >
        <v-card-title
          class="custom-modal-title"
          primary-title
        >
          Select Menu
          <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"

            single-line
            hide-details
            ></v-text-field>
            <v-list
              subheader
              two-line
            >
              <v-list-item
                v-for="(folder,index) in menu"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-checkbox
                    v-model="selected"
                    :key="folder.id"
                    :value="folder.id"
                    @change="checkhed_data(folder.id)"
                  ></v-checkbox>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="custom-list-item-title-modal" v-text="folder.main_menu"></v-list-item-title>
                  <v-list-item-subtitle style="color: black !important;" >  {{folder.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            outlined
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            outlined
            :disabled="!valid"
            @click="save_cart()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'

export default {
  props: ['user_edit'],
  mixins:[GlobalMixin],
  methods: {
    ...mapActions({
      setSnakbar: 'snakbar/setSnakbar',
      setCart:'cart/setCart'
    }),

    close () {
      this.$emit('close')
    },

    async get_data_master (table) {
      await this.axios.get(`/v1/${table}/all?`, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          console.log(ress)
          await ress.data.data.forEach(element => {
            this.menu.push({
            ...element,
            checkhed:false})
          });
          if (this.edit_data) {
            this.edit_data.menu.forEach(x => {
              let index = this.menu.findIndex(element=>element.id == x.id)
              x.checkhed = true
              this.selected.push(x.id)
              this.menu.splice(index,1,x)
            })

          }

        })
        .catch(err => console.log(err))
        this.progress = false
    },
    go(){
      this.$emit('go')
    },
    async save_cart() {
      let data = new FormData()
      await this.menu.forEach(element => {
        if (element.checkhed === true) {
          data.append('menu[]',element.id)

        }
      });
      await this.axios.post('v1/users-role/access/' + this.edit_data.id,data)
      .then(async (ress) => {
        this.setSnakbar({
            status: true,
            pesan: 'success update data',
            color_snakbar: 'success'
          })
          await this.go()
          this.close()
      })
      .catch((err) => {
        console.log(err.response)
      })

    },
    async checkhed_data(id) {
      let cart_selected =await this.menu.find(element => element.id === id)
      let index = this.menu.findIndex(x => x.id === id)
      cart_selected.checkhed = !cart_selected.checkhed
      this.menu.splice(index,1,cart_selected)
    }

  },

  watch: {
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog:true,
    progress:true,
    cart_data:[],
    selected:[],
    search:'',
    menu:[]
  }),

  computed: {
    ...mapGetters({
      color: 'layout/color',
      user: 'auth/user',
    }),
  },
  filters:{

  },

  async created () {

    await this.get_data_master('menu-access')

  }
}
</script>
